import { ausConfig } from "./ausConfig";
import { laConfig } from "./laConfig";
import { pdxConfig } from "./pdxConfig";
import { sanConfig } from "./sanConfig";
import { seaConfig } from "./seaConfig";
import { yamConfig } from "./yamConfig";

type Subdomain = "sea" | "san" | "pdx" | "aus" | "la" | "yam";

export const CONFIG_MAP: Record<Subdomain, RegionConfig> = {
  sea: seaConfig,
  san: sanConfig,
  pdx: pdxConfig,
  aus: ausConfig,
  la: laConfig,
  yam: yamConfig
};

const hostname = window.location.hostname;
const subdomain = hostname
  .split(".")[0]
  .replace("-0", "") as unknown as Subdomain;

const config = CONFIG_MAP[subdomain] ?? laConfig;

export default config as RegionConfig;
